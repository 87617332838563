








import Vue from 'vue';

import CollapsibleStepBlockInjectHTML from '@/components/blocks/CollapsibleStepBlockInjectHTML.vue';

export default Vue.extend({
  name: 'AddProduct',
  components: {
    CollapsibleStepBlockInjectHTML,
  },
  computed: {
    i18nOpt(): Record<string, unknown> {
      return {
        SetupAddProduct1: require('@/assets/setupAppGuide/AddProduct/SetupAddProduct1.png'),
        SetupAddProduct2: require('@/assets/setupAppGuide/AddProduct/SetupAddProduct2.png'),
        SetupAddProduct3: require('@/assets/setupAppGuide/AddProduct/SetupAddProduct3.png'),
        SetupAddProduct4: require('@/assets/setupAppGuide/AddProduct/SetupAddProduct4.png'),
        SetupAddProduct5: require('@/assets/setupAppGuide/AddProduct/SetupAddProduct5.png'),
        SetupAddProduct6: require('@/assets/setupAppGuide/AddProduct/SetupAddProduct6.png'),
        SetupAddProduct7: require('@/assets/setupAppGuide/AddProduct/SetupAddProduct7.png'),
        SetupAddProduct8: require('@/assets/setupAppGuide/AddProduct/SetupAddProduct8.png'),
        SetupAddProduct9: require('@/assets/setupAppGuide/AddProduct/SetupAddProduct9.png'),
        SetupAddProduct10: require('@/assets/setupAppGuide/AddProduct/SetupAddProduct10.png'),
        SetupAddProduct11: require('@/assets/setupAppGuide/AddProduct/SetupAddProduct11.png'),
        SetupAddProduct12: require('@/assets/setupAppGuide/AddProduct/SetupAddProduct12.png'),
        SetupAddProduct13: require('@/assets/setupAppGuide/AddProduct/SetupAddProduct13.png'),
        SetupAddProduct14: require('@/assets/setupAppGuide/AddProduct/SetupAddProduct14.png'),
        SetupAddProduct15: require('@/assets/setupAppGuide/AddProduct/SetupAddProduct15.png'),
        SetupAddProduct16: require('@/assets/setupAppGuide/AddProduct/SetupAddProduct16.png'),
        SetupAddProduct17: require('@/assets/setupAppGuide/AddProduct/SetupAddProduct17.png'),
        SetupAddProduct18: require('@/assets/setupAppGuide/AddProduct/SetupAddProduct18.png'),
        SetupAddProduct19: require('@/assets/setupAppGuide/AddProduct/SetupAddProduct19.png'),
        SetupAddProduct20: require('@/assets/setupAppGuide/AddProduct/SetupAddProduct20.png'),
        SetupAddProduct21: require('@/assets/setupAppGuide/AddProduct/SetupAddProduct21.png'),
        SetupAddProduct22: require('@/assets/setupAppGuide/AddProduct/SetupAddProduct22.png'),
        SetupAddProduct23: require('@/assets/setupAppGuide/AddProduct/SetupAddProduct23.png'),
        SetupAddProduct24: require('@/assets/setupAppGuide/AddProduct/SetupAddProduct24.png'),
      };
    },
    contents(): { keys: string[]; opt?: Record<string, unknown> } {
      return {
        keys: ['HowToInstall.SetupAppStepByStepGuide.AddProducts.Content'],
        opt: this.i18nOpt,
      };
    },
  },
});
