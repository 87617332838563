




















































import vClickOutside from 'v-click-outside';
import Vue from 'vue';

import { AppLocale, AppLocaleData, AppLocaleDatas } from '@/enums/AppLocale';

import MenuItem from './MenuItem.vue';

interface IState {
  showMenu: boolean;
}

export default Vue.extend({
  name: 'LanguagePicker',
  components: {
    MenuItem,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    isSticky: {
      type: Boolean,
      default: false,
    },
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  data(): IState {
    return {
      showMenu: false,
    };
  },
  computed: {
    availableLocales(): AppLocaleData[] {
      return AppLocaleDatas;
    },
    current(): AppLocale {
      return this.$store.state.locale;
    },
  },
  methods: {
    getLocaleLabel(locale: AppLocale): string {
      return AppLocaleDatas.find((d) => d.locale === locale)?.label || locale;
    },
    selectLocale(locale: AppLocale) {
      this.$store.dispatch('changeLocale', locale);
      this.showMenu = false;
    },
    onLocaleSelect(locale: AppLocaleData) {
      this.selectLocale(locale.locale);
    },
    outsideClick() {
      this.showMenu = false;
    },
  },
});
