








import Vue from 'vue';

import CollapsibleStepBlockInjectHTML from '@/components/blocks/CollapsibleStepBlockInjectHTML.vue';

export default Vue.extend({
  name: 'SyncProducts',
  components: {
    CollapsibleStepBlockInjectHTML,
  },
  computed: {
    i18nOpt(): Record<string, unknown> {
      return {
        Sync1: require('@/assets/setupAppGuide/SyncProducts/Sync1.png'),
        Sync2: require('@/assets/setupAppGuide/SyncProducts/Sync2.png'),
      };
    },
    contents(): { keys: string[]; opt?: Record<string, unknown> } {
      return {
        keys: ['HowToInstall.SetupAppStepByStepGuide.SyncProducts.Content'],
        opt: this.i18nOpt,
      };
    },
  },
});
