










import Vue from 'vue';

import CollapsibleStepBlockInjectHTML from '@/components/blocks/CollapsibleStepBlockInjectHTML.vue';

export default Vue.extend({
  name: 'DecideOnConnectivity',
  components: {
    CollapsibleStepBlockInjectHTML,
  },
  computed: {
    i18nOpt(): Record<string, unknown> {
      return {
        SetupConnectivity1: require('@/assets/setupAppGuide/DecideOnConnectivity/SetupConnectivity1.png'),
        SetupConnectivity2: require('@/assets/setupAppGuide/DecideOnConnectivity/SetupConnectivity2.png'),
        SetupConnectivity3: require('@/assets/setupAppGuide/DecideOnConnectivity/SetupConnectivity3.png'),
      };
    },
    contents(): { keys: string[]; opt?: Record<string, unknown> } {
      return {
        keys: [
          'HowToInstall.SetupAppStepByStepGuide.DecideOnConnectivity.Content',
        ],
        opt: this.i18nOpt,
      };
    },
  },
});
