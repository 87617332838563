
















































































































































































































































import Vue from 'vue';

import AppleStoreButton from '@/components/AppStoreButtons/AppleStore.vue';
import GoogleStoreButton from '@/components/AppStoreButtons/GoogleStore.vue';
import AnchorTag from '@/components/buttons/AnchorTag.vue';
import OutlinedButton from '@/components/buttons/OutlinedButton.vue';
import TabDecoration from '@/components/NavigationHeader/TabDecoration.vue';
import CutBlock from '@/components/templates/CutBlock.vue';
import CutBlockWithStripes from '@/components/templates/CutBlockWithStripe.vue';
import {AppLocale} from '@/enums/AppLocale';
import {navigateToURL} from '@/utilities/URLNavigation';

export default Vue.extend({
  name: 'SetupApp',
  components: {
    CutBlockWithStripes,
    CutBlock,
    OutlinedButton,
    AnchorTag,
    AppleStoreButton,
    GoogleStoreButton,
    TabDecoration,
  },
  data(): {
    showScenario: 1 | 2 | 3;
  } {
    return {
      showScenario: 1,
    };
  },
  computed: {
    faqWizProSetupLink(): string {
      return process.env.VUE_APP_FAQ_WiZ_PRO_SETUP_LINK;
    },
    showingScenario1(): boolean {
      return this.showScenario === 1;
    },
    showingScenario2(): boolean {
      return this.showScenario === 2;
    },
    showingScenario3(): boolean {
      return this.showScenario === 3;
    },
    currentLocale(): string {
      return this.$store.state.locale;
    },
  },
  methods: {
    navigateTo(url: string) {
      navigateToURL(url);
    },
    goToWizProSetupFAQ() {
      this.navigateTo(this.faqWizProSetupLink);
    },
    goToHowToInstall() {
      this.$router.push('/how-to-install');
    },
    scenarioImageSrc(index: number): string {
      let fileName = 'Scenario';
      fileName += index;
      if (this.currentLocale === AppLocale.CN) {
        fileName += 'CN';
      }
      return require(`@/assets/setupApp/${fileName}.png`);
    },
  },
});
