



















import Vue from 'vue';

export default Vue.extend({
  name: 'CutBlock',
  props: {
    topColor: {
      type: String,
      required: true,
    },
    bottomColor: {
      type: String,
      required: true,
    },
  },
});
