var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"font-rubik relative",staticStyle:{"height":"96px"}},[_c('div',{ref:"header",class:[
      'h-24 flex flex-row justify-center transition-all fixed w-full z-50',
      _vm.isSticky ? 'bg-white' : 'bg-dark' ]},[_c('div',{staticClass:"\n        flex flex-row flex-wrap flex-shrink-0\n        items-center justify-between\n        container\n        relative\n        w-full px-5 md:px-0\n      "},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{class:_vm.isSticky ? 'hidden' : 'block',staticStyle:{"width":"104px","height":"56px"},attrs:{"src":require("@/assets/logo.svg"),"alt":"WiZ Logo"}}),_c('img',{class:_vm.isSticky ? 'block' : 'hidden',staticStyle:{"width":"104px","height":"56px"},attrs:{"src":require("@/assets/logo-inverted.svg"),"alt":"WiZ Logo"}})]),_c('button',{class:[
          'inline-block md:hidden w-8 h-8 bg-transparent text-gray-600 p-1',
          _vm.isSticky ? 'text-black' : 'text-white' ],on:{"click":_vm.toggleMenu}},[_c('svg',{attrs:{"fill":"currentColor","viewBox":"0 0 20 20","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0\n            011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z","clip-rule":"evenodd"}})])]),_c('div',{class:[
          'absolute md:relative left-0 top-full md:top-0',
          'z-50 p-5 md:p-0',
          'w-full md:w-auto',
          'bg-white md:bg-transparent',
          'text-black md:text-white text-left md:text-center',
          'md:block',
          _vm.isResponsiveMenuDroppedDown ? 'block' : 'hidden' ]},[_c('div',{class:[
            'flex flex-row flex-wrap md:flex-no-wrap',
            'md:space-x-10',
            'space-y-4 md:space-y-0',
            'mb-4 md:mb-0',
            'font-rubik text-base text-black',
            _vm.isSticky ? 'md:text-black' : 'md:text-white' ]},[_c('router-link',{class:['navigation-header-tab',
              _vm.isCurrentPageHome ? 'text-wizBlue md:text-inherit' : 'text-inherit'],attrs:{"to":"/"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('NavigationBar.Home'))}}),_c('TabDecoration',{attrs:{"selected":_vm.isCurrentPageHome,"isSticky":_vm.isSticky}})],1),_c('a',{staticClass:"navigation-header-tab",attrs:{"href":_vm.proDashboardLink}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('NavigationBar.Dashboard'))}}),_c('TabDecoration',{attrs:{"isSticky":_vm.isSticky}})],1),_c('router-link',{class:['navigation-header-tab',
              _vm.isCurrentPageSetupApp ? 'text-wizBlue md:text-inherit' : 'text-inherit'],attrs:{"to":"/setup-app"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('NavigationBar.SetupApp'))}}),_c('TabDecoration',{attrs:{"selected":_vm.isCurrentPageSetupApp,"isSticky":_vm.isSticky}})],1),_c('router-link',{class:['navigation-header-tab',
              _vm.isCurrentPageHowToInstall ? 'text-wizBlue md:text-inherit' : 'text-inherit'],attrs:{"to":"/how-to-install"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('NavigationBar.HowToInstall'))}}),_c('TabDecoration',{attrs:{"selected":_vm.isCurrentPageHowToInstall,"isSticky":_vm.isSticky}})],1),_c('language-picker',{staticClass:"md:hidden",attrs:{"is-sticky":_vm.isSticky,"mobile":""}})],1)]),_c('div',{staticClass:"hidden md:block"},[_c('language-picker',{attrs:{"is-sticky":_vm.isSticky}})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }