







import Vue from 'vue';

export default Vue.extend({
  name: 'VersionInfo',
  computed: {
    isDev: () => process.env.NODE_ENV === 'development',
    version: () => [process.env.VUE_APP_ENV, process.env.VUE_APP_VERSION].filter(Boolean).join('-'),
  },
});
