















import Vue from 'vue';

export default Vue.extend({
  name: 'OutlinedButton',
  props: {
    variant: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
    },
  },
  data(): { hover: boolean } {
    return {
      hover: false,
    };
  },
  computed: {
    styles(): { [key: string]: string } {
      let primaryColor;
      let secondaryColor;
      let secondaryTextColor;
      switch (this.variant) {
        case 'white':
          primaryColor = '#0089cb';
          secondaryColor = '#0089cb';
          secondaryTextColor = '#ffffff';
          break;
        case 'black':
          primaryColor = '#ffffff';
          secondaryColor = '#0089cb';
          secondaryTextColor = primaryColor;
          break;
        case 'blue':
        default:
          primaryColor = '#ffffff';
          secondaryColor = '#ffffff';
          secondaryTextColor = '#0089cb';
          break;
      }
      if (this.active) {
        return {
          'border-color': secondaryColor,
          'background-color': secondaryColor,
          color: secondaryTextColor,
        };
      }
      return !this.hover
        ? {
          'border-color': primaryColor,
          color: primaryColor,
        }
        : {
          'border-color': secondaryColor,
          'background-color': secondaryColor,
          color: secondaryTextColor,
        };
    },
  },
});
