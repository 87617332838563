




import Vue from 'vue';

export default Vue.extend({
  name: 'ServiceCardTitle',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
});
