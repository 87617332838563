









































































































































































import Vue from 'vue';

import AppleStoreButton from '@/components/AppStoreButtons/AppleStore.vue';
import GoogleStoreButton from '@/components/AppStoreButtons/GoogleStore.vue';
import InstallationFlow from '@/components/blocks/InstallationFlow.vue';
import AnchorTag from '@/components/buttons/AnchorTag.vue';
import OutlinedButton from '@/components/buttons/OutlinedButton.vue';
import ServiceIcon from '@/components/pictograms/ServiceIcon.vue';
import BulletListItem from '@/components/templates/BulletListItem.vue';
import CutBlock from '@/components/templates/CutBlock.vue';
import CutBlockWithStripes from '@/components/templates/CutBlockWithStripe.vue';
import ServiceCardSmall from '@/components/templates/ServiceCardSmall.vue';
import ServiceCardsWrapper from '@/components/templates/ServiceCardsWrapper.vue';
import ServiceCardTitle from '@/components/templates/ServiceCardTitle.vue';
import {AppLocale} from '@/enums/AppLocale';
import {scrollToElementWithOffset} from '@/utilities/ScrollUtilities';
import {navigateToURL} from '@/utilities/URLNavigation';

export default Vue.extend({
  name: 'Home',
  components: {
    CutBlockWithStripes,
    CutBlock,
    OutlinedButton,
    ServiceCardSmall,
    ServiceCardTitle,
    ServiceCardsWrapper,
    ServiceIcon,
    BulletListItem,
    InstallationFlow,
    AnchorTag,
    AppleStoreButton,
    GoogleStoreButton,
  },
  computed: {
    proDashboardLink(): string {
      return process.env.VUE_APP_DASHBOARD_HOST;
    },
  },
  methods: {
    navigateTo(url: string) {
      navigateToURL(url);
    },
    routeToHowToInstall() {
      this.$router.push({name: 'HowToInstall'});
    },
    routeToSetupApp() {
      this.$router.push({name: 'SetupApp'});
    },
    scrollToSoftwareSuite() {
      const {softwareSuiteBlock} = this.$refs;
      scrollToElementWithOffset((softwareSuiteBlock as Vue).$el, -90);
    },
    showToast(type: string, locale: AppLocale | undefined = AppLocale.EN) {
      this.$i18n.locale = locale;

      // Decode the base-64 encoded type string
      const decodedType = atob(type);
      const TYPES = {
        EMAIL_CONFIRM_SUCCESS: 'emailConfirmSuccess',
        EMAIL_CONFIRM_FAIL: 'emailConfirmFail',
      };

      switch (decodedType) {
        case TYPES.EMAIL_CONFIRM_SUCCESS:
          this.$awn.success(this.$t('Toast.EmailConfirmSuccess') as string);
          break;
        case TYPES.EMAIL_CONFIRM_FAIL:
          this.$awn.alert(this.$t('Toast.EmailConfirmFail') as string);
          break;
        default:
      }

      window.history.replaceState({}, document.title, '/');
    },
  },
  mounted() {
    const {type, locale} = this.$route.query;
    if (type) {
      this.showToast(type as string, locale as AppLocale);
    }
  },
});
