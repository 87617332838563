








import Vue from 'vue';

import CollapsibleStepBlockInjectHTML from '@/components/blocks/CollapsibleStepBlockInjectHTML.vue';

export default Vue.extend({
  name: 'Handover',
  components: {
    CollapsibleStepBlockInjectHTML,
  },
  computed: {
    i18nOpt(): Record<string, unknown> {
      return {
        Handover1: require('@/assets/setupAppGuide/Handover/Handover1.png'),
        Handover2: require('@/assets/setupAppGuide/Handover/Handover2.png'),
      };
    },
    contents(): { keys: string[]; opt?: Record<string, unknown> } {
      return {
        keys: ['HowToInstall.SetupAppStepByStepGuide.Handover.Content'],
        opt: this.i18nOpt,
      };
    },
  },
});
