var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.outsideClick),expression:"outsideClick"}]},[_c('div',{staticClass:"\n      uppercase\n      flex flex-row flex-nowrap\n      align-center\n      space-x-3\n      pr-3.5\n      pb-1\n      cursor-pointer\n    ",on:{"click":function($event){$event.preventDefault();_vm.showMenu = !_vm.showMenu}}},[_c('span',{class:_vm.isSticky ? 'md:text-black' : 'md:text-white'},[_vm._v(" "+_vm._s(_vm.getLocaleLabel(_vm.current))+" ")]),_c('img',{staticClass:"w-4",attrs:{"src":require("@/assets/icons/pointerDown.svg")}})]),_c('div',{class:[
      'menu-anchor relative w-0 mt-1',
      _vm.mobile ? 'mr-auto h-auto' : 'h-0 ml-auto' ]},[_c('div',{class:['menu z-50', _vm.mobile ? '' : 'absolute top-0 right-0']},[_c('div',{class:[
          { 'menu-wrapper': !_vm.mobile },
          { isSticky: _vm.isSticky },
          'w-max rounded-lg',
          'overflow-hidden ease-in-out duration-500',
          _vm.showMenu ? 'max-h-96' : 'max-h-0' ]},[_c('div',{staticClass:"w-full px-4 py-2.5 space-y-4"},[_vm._l((_vm.availableLocales),function(locale){return [(locale.locale !== _vm.current)?_c('menu-item',{key:locale.locale,attrs:{"locale":locale,"mobile":_vm.mobile},on:{"select":_vm.onLocaleSelect}}):_vm._e()]})],2)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }