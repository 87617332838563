import './index.css';
import './scroll-animation';

import vClickOutside from 'v-click-outside';
import Vue from 'vue';
import VueAWN from 'vue-awesome-notifications';
import VueI18n from 'vue-i18n';
import Vuex from 'vuex';

import { getCachedLocalePreference } from "@/enums/AppLocale";
import { initiateLocalization } from '@/localization/localization';
import { initiateStore } from '@/store/store';

import App from './App.vue';
import router, { setupBeforeEachGuard } from './router';

const defaultLocale = getCachedLocalePreference();

Vue.use(VueI18n);
const i18n = initiateLocalization(defaultLocale);

Vue.use(Vuex);
const store = initiateStore(i18n);

setupBeforeEachGuard(router, store);

Vue.use(vClickOutside);

Vue.config.productionTip = false;

const DURATIONS = {
  ANIMATION: 300,
  GLOBAL: 4000,
  SUCCESS: 2000,
};

const VueAWNOptions = {
  icons: { enabled: false },
  labels: { alert: '', success: '', info: '' },
  animationDuration: DURATIONS.ANIMATION,
  durations: {
    global: DURATIONS.GLOBAL,
    success: DURATIONS.SUCCESS,
  },
};
Vue.use(VueAWN, VueAWNOptions);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
