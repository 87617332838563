








import Vue from 'vue';

import CollapsibleStepBlockInjectHTML from '@/components/blocks/CollapsibleStepBlockInjectHTML.vue';

export default Vue.extend({
  name: 'InviteMemberAsOwner',
  components: {
    CollapsibleStepBlockInjectHTML,
  },
  computed: {
    i18nOpt(): Record<string, unknown> {
      return {
        Invite1: require('@/assets/guide/InviteDescription/Invite1.png'),
        Invite2: require('@/assets/guide/InviteDescription/Invite2.png'),
      };
    },
    contents(): {keys: string[]; opt?: Record<string, unknown>} {
      return {
        keys: ['HowToInstall.StepByStepGuide.InstallUsingWizApp.InviteDescription'],
        opt: this.i18nOpt,
      };
    },
  },
});
