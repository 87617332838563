








import Vue from 'vue';

import CollapsibleStepBlockInjectHTML from '@/components/blocks/CollapsibleStepBlockInjectHTML.vue';

export default Vue.extend({
  name: 'InstallDevicesWithApp',
  components: {
    CollapsibleStepBlockInjectHTML,
  },
  computed: {
    i18nOpt(): Record<string, unknown> {
      return {
        AdddeviceWiZApp1: require('@/assets/guide/InstallDevicesDescription/AdddeviceWiZApp1.png'),
        AdddeviceWiZApp2: require('@/assets/guide/InstallDevicesDescription/AdddeviceWiZApp2.png'),
        AdddeviceWiZApp3: require('@/assets/guide/InstallDevicesDescription/AdddeviceWiZApp3.png'),
        AdddeviceWiZApp4: require('@/assets/guide/InstallDevicesDescription/AdddeviceWiZApp4.png'),
        AdddeviceWiZApp5: require('@/assets/guide/InstallDevicesDescription/AdddeviceWiZApp5.png'),
        AdddeviceWiZApp6: require('@/assets/guide/InstallDevicesDescription/AdddeviceWiZApp6.png'),
        AdddeviceWiZApp7: require('@/assets/guide/InstallDevicesDescription/AdddeviceWiZApp7.png'),
        AdddeviceWiZApp8: require('@/assets/guide/InstallDevicesDescription/AdddeviceWiZApp8.png'),
        AdddeviceWiZApp9: require('@/assets/guide/InstallDevicesDescription/AdddeviceWiZApp9.png'),
        AdddeviceWiZApp10: require('@/assets/guide/InstallDevicesDescription/AdddeviceWiZApp10.png'),
        AdddeviceWiZApp11: require('@/assets/guide/InstallDevicesDescription/AdddeviceWiZApp11.png'),
        AdddeviceWiZApp12: require('@/assets/guide/InstallDevicesDescription/AdddeviceWiZApp12.png'),
        AdddeviceWiZApp13: require('@/assets/guide/InstallDevicesDescription/AdddeviceWiZApp13.png'),
      };
    },
    contents(): {keys: string[]; opt?: Record<string, unknown>} {
      return {
        keys: ['HowToInstall.StepByStepGuide.InstallUsingWizApp.InstallDevicesDescription'],
        opt: this.i18nOpt,
      };
    },
  },
});
