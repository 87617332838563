





























































































































import Vue from 'vue';

import LanguagePicker from '@/components/header/LanguagePicker.vue';
import TabDecoration from '@/components/NavigationHeader/TabDecoration.vue';

interface IState {
  isSticky: boolean;
  isResponsiveMenuDroppedDown: boolean;
  currentPath: string;
}

export default Vue.extend({
  name: 'NavigationHeader',
  components: {
    LanguagePicker,
    TabDecoration,
  },
  data(): IState {
    return {
      isSticky: false,
      isResponsiveMenuDroppedDown: false,
      currentPath: this.$router.currentRoute.path,
    };
  },
  watch: {
    $route() {
      this.currentPath = this.$router.currentRoute.path;
      if (this.isResponsiveMenuDroppedDown) {
        this.toggleMenu();
      }
    },
  },
  mounted() {
    const header = this.$refs.header as HTMLElement;
    if (header) {
      window.document.onscroll = () => {
        this.isSticky = window.scrollY > header.offsetTop;
      };
    }
  },
  computed: {
    currentLocale(): string {
      return this.$store.state.locale;
    },
    proDashboardLink(): string {
      return `${process.env.VUE_APP_DASHBOARD_HOST}/#/?locale=${this.currentLocale}`;
    },
    isCurrentPageHome(): boolean {
      return this.currentPath === '/';
    },
    isCurrentPageSetupApp(): boolean {
      return this.currentPath === '/setup-app';
    },
    isCurrentPageHowToInstall(): boolean {
      return this.currentPath === '/how-to-install';
    },
  },
  methods: {
    toggleMenu() {
      this.isResponsiveMenuDroppedDown = !this.isResponsiveMenuDroppedDown;
    },
  },
});
