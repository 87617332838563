








import Vue from 'vue';

import CollapsibleStepBlockInjectHTML from '@/components/blocks/CollapsibleStepBlockInjectHTML.vue';

export default Vue.extend({
  name: 'CreateSite',
  components: {
    CollapsibleStepBlockInjectHTML,
  },
  computed: {
    i18nOpt(): Record<string, unknown> {
      return {
        CreateOrJoin1: require('@/assets/setupAppGuide/CreateSite/CreateOrJoin1.png'),
        CreateOrJoin2: require('@/assets/setupAppGuide/CreateSite/CreateOrJoin2.png'),
        CreateOrJoin3: require('@/assets/setupAppGuide/CreateSite/CreateOrJoin3.png'),
        CreateOrJoin4: require('@/assets/setupAppGuide/CreateSite/CreateOrJoin4.png'),
      };
    },
    contents(): { keys: string[]; opt?: Record<string, unknown> } {
      return {
        keys: ['HowToInstall.SetupAppStepByStepGuide.CreateSite.Content'],
        opt: this.i18nOpt,
      };
    },
  },
});
