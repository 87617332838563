




import {SpinnerSize} from '@/enums/SpinnerSize';

import GenericSpinner from './genericSpinner/GenericSpinner.vue';

export default {
  name: 'BigSpinner',
  components: {
    GenericSpinner,
  },
  computed: {
    spinnerSize(): SpinnerSize {
      return SpinnerSize.big;
    },
  },
};
