




















































import Vue from 'vue';

import { SpinnerSize } from '@/enums/SpinnerSize';

export default Vue.extend({
  props: {
    size: {
      type: Number as () => SpinnerSize,
      required: true,
    },
  },
  computed: {
    sizeSpecifiedBlock(): string {
      switch (this.size) {
        case SpinnerSize.small:
          throw new Error('Small size is not supported by the light bulb spinner');
        case SpinnerSize.medium:
          return 'medium-spinner-block';
        case SpinnerSize.big:
          return 'large-spinner-block';
        default:
          throw new Error('Size is not supported by the light bulb spinner');
      }
    },
    sizeSpecifiedContainer(): string {
      switch (this.size) {
        case SpinnerSize.small:
          throw new Error('Small size is not supported by the light bulb spinner');
        case SpinnerSize.medium:
          return 'medium-spinner-container';
        case SpinnerSize.big:
          return 'large-spinner-container';
        default:
          throw new Error('Size is not supported by the light bulb spinner');
      }
    },
  },
});
