








import Vue from 'vue';

import CollapsibleStepBlockInjectHTML from '@/components/blocks/CollapsibleStepBlockInjectHTML.vue';

export default Vue.extend({
  name: 'CreateOrganization',
  components: {
    CollapsibleStepBlockInjectHTML,
  },
  computed: {
    link(): string {
      return process.env.VUE_APP_DASHBOARD_HOST;
    },
    i18nOpt(): Record<string, unknown> {
      return {
        link: this.link,
        CreateOrganization1: require('@/assets/guide/CreateOrganization/CreateOrganization1.png'),
        CreateOrganization2: require('@/assets/guide/CreateOrganization/CreateOrganization2.png'),
        CreateOrganization3: require('@/assets/guide/CreateOrganization/CreateOrganization3.png'),
      };
    },
    contents(): {keys: string[]; opt?: Record<string, unknown>} {
      return {
        keys: ['HowToInstall.StepByStepGuide.CreateOrganization.Description'],
        opt: this.i18nOpt,
      };
    },
  },
});
