












import Vue from 'vue';

import Copyright from '@/components/Copyright.vue';
import NavigationFooter from '@/components/NavigationFooter.vue';
import NavigationHeader from '@/components/NavigationHeader/index.vue';

export default Vue.extend({
  name: 'App',
  components: {
    NavigationHeader,
    NavigationFooter,
    Copyright,
  },
});
