import Vue from 'vue';
import VueRouter, {Route, RouteConfig} from 'vue-router';
import {Store} from 'vuex';

import {getLocaleData, isLocaleSupported} from '@/enums/AppLocale';
import {AppStore} from '@/store/store';
import Home from '@/views/Home.vue';
import HowToInstall from '@/views/HowToInstall.vue';
import SetupApp from '@/views/SetupApp.vue';
import TemplatePage from '@/views/TemplatePage.vue';

Vue.use(VueRouter);

/*
  When adding routes below, please add a rewrite config to the CN specific Nginx configuration
  or else the routing will not work in dev / prod

  i.e. in Nginx:
  location / {
    ...
    ### CN-specific REWRITE section
    rewrite /how-to-install / last;
    ### END OF CN-specific REWRITE section
    ...
  }
*/

const routes: RouteConfig[] = [
  {
    path: '/how-to-install',
    name: 'HowToInstall',
    component: HowToInstall,
  },
  {
    path: '/setup-app',
    name: 'SetupApp',
    component: SetupApp,
  },
  {
    path: '/termsAndConditions',
    name: 'TermsAndConditions',
    component: TemplatePage,
    props: {
      url: process.env.VUE_APP_TERMS_OF_SERVICE_URL,
    },
  },
  {
    path: '/privacyNotice',
    name: 'PrivacyNotice',
    component: TemplatePage,
    props: {
      url: process.env.VUE_APP_PRIVACY_NOTICE_URL,
    },
  },
  {
    path: '/cookiePolicy',
    name: 'CookiePolicy',
    component: TemplatePage,
    props: {
      url: process.env.VUE_APP_COOKIE_POLICY_URL,
    },
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {selector: to.hash, behavior: 'smooth', offset: {x: 0, y: 96}};
    }
    return {x: 0, y: 0};
  },
});

export default router;

function getFirstUrlQueryValue(queries?: string | (string | null)[]): string {
  if (Array.isArray(queries)) return queries[0] || '';
  return queries || '';
}

function deleteKeyInQuery(query: Route['query'], key: string): Route['query'] {
  // remove the query after it is consumed, otherwise user cannot switch locale in this page
  const newQuery = {
    ...query,
  };
  delete newQuery[key];
  return newQuery;
}

export function setupBeforeEachGuard(routerInstance: VueRouter, store: Store<AppStore>): void {
  routerInstance.beforeEach((to, _from, next) => {
    const {query} = to;

    // Add `locale=<locale>` to url to set locale
    const localeFromQuery = getFirstUrlQueryValue(query.locale);
    if (!localeFromQuery) {
      next();
      return;
    }

    const supported = isLocaleSupported(localeFromQuery);
    if (!supported) {
      const newQuery = deleteKeyInQuery(query, 'locale');
      next({path: to.path, query: newQuery});
      return;
    }

    const localeData = getLocaleData(localeFromQuery);
    store.dispatch('changeLocale', localeData.locale);
    const newQuery = deleteKeyInQuery(query, 'locale');
    next({path: to.path, query: newQuery});
  });
}
