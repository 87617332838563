

















import Vue from 'vue';

import AppleStoreButton from '@/components/AppStoreButtons/AppleStore.vue';
import GoogleStoreButton from '@/components/AppStoreButtons/GoogleStore.vue';
import CollapsibleStepBlock from '@/components/blocks/CollapsibleStepBlock.vue';

export default Vue.extend({
  name: 'DownloadWizApp',
  components: {
    CollapsibleStepBlock,
    AppleStoreButton,
    GoogleStoreButton,
  },
  computed: {
    i18nOpt(): Record<string, unknown> {
      return {
        ExistingUserAnd1: require('@/assets/guide/InstallUsingWizApp/ExistingUserAnd1.png'),
        ExistingUserAnd2: require('@/assets/guide/InstallUsingWizApp/ExistingUserAnd2.png'),
        ExistingUserAnd3: require('@/assets/guide/InstallUsingWizApp/ExistingUserAnd3.png'),
        ExistingUserAnd4: require('@/assets/guide/InstallUsingWizApp/ExistingUserAnd4.png'),
        ExistingUserAnd5: require('@/assets/guide/InstallUsingWizApp/ExistingUserAnd5.png'),
        ExistingUseriOS1: require('@/assets/guide/InstallUsingWizApp/ExistingUseriOS1.png'),
        ExistingUseriOS2: require('@/assets/guide/InstallUsingWizApp/ExistingUseriOS2.png'),
        ExistingUseriOS3: require('@/assets/guide/InstallUsingWizApp/ExistingUseriOS3.png'),
        ExistingUseriOS4: require('@/assets/guide/InstallUsingWizApp/ExistingUseriOS4.png'),
        ExistingUseriOS5: require('@/assets/guide/InstallUsingWizApp/ExistingUseriOS5.png'),
        JoinAHomeFresh1: require('@/assets/guide/InstallUsingWizApp/JoinAHomeFresh1.png'),
        JoinAHomeFresh2: require('@/assets/guide/InstallUsingWizApp/JoinAHomeFresh2.png'),
        JoinAHomeFresh3: require('@/assets/guide/InstallUsingWizApp/JoinAHomeFresh3.png'),
      };
    },
  },
});
