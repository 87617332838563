































































































import Vue from 'vue';

import TabDecoration from '@/components/NavigationHeader/TabDecoration.vue';
import {AppLocale} from '@/enums/AppLocale';

export default Vue.extend({
  name: 'NavigationFooter',
  components: {
    TabDecoration,
  },
  computed: {
    proDashboardLink(): string {
      return process.env.VUE_APP_DASHBOARD_HOST;
    },
    faqWizProSetupLink(): string {
      return process.env.VUE_APP_FAQ_WiZ_PRO_SETUP_LINK;
    },
    faqWizProDashboardLink(): string {
      return process.env.VUE_APP_FAQ_WiZ_PRO_DASHBOARD_LINK;
    },
    currenSelectedLocale(): AppLocale {
      return this.$store.state.locale;
    },
    aboutWizLink(): string {
      let localeForLink = '';
      let link = `${process.env.VUE_APP_ABOUT_WIZ_LINK}`;

      switch (this.currenSelectedLocale) {
        case AppLocale.CN:
          localeForLink = 'zh-cn';
          // the site auto redirect to .cn root when locale zh-cn is detected
          // but fail to redirect to the requested page.
          link = link.replace('.com', '.cn');
          break;
        // case AppLocale.FR:
        //   localeForLink ='fr-fr';
        // case AppLocale.RU:
        //   localeForLink ='ru-ru';
        case AppLocale.EN:
        default:
          localeForLink = 'en-us';
          break;
      }
      return link.replace('{{locale}}', localeForLink);
    },
  },
});
