








import Vue from 'vue';

import CollapsibleStepBlockInjectHTML from '@/components/blocks/CollapsibleStepBlockInjectHTML.vue';

export default Vue.extend({
  name: 'ActivateLicenses',
  components: {
    CollapsibleStepBlockInjectHTML,
  },
  computed: {
    i18nOpt(): Record<string, unknown> {
      return {
        SetupAppActivateLicense1: require('@/assets/setupAppGuide/ActivateLicenses/SetupAppActivateLicense1.jpg'),
        SetupAppActivateLicense2: require('@/assets/setupAppGuide/ActivateLicenses/SetupAppActivateLicense2.jpg'),
        SetupAppActivateLicense3: require('@/assets/setupAppGuide/ActivateLicenses/SetupAppActivateLicense3.jpg'),
      };
    },
    contents(): { keys: string[]; opt?: Record<string, unknown> } {
      return {
        keys: ['HowToInstall.SetupAppStepByStepGuide.ActivateLicenses.Content'],
        opt: this.i18nOpt,
      };
    },
  },
});
