






























import Vue from 'vue';

import CollapsibleStepBlock from '@/components/blocks/CollapsibleStepBlock.vue';

export default Vue.extend({
  name: 'HowToConfigure',
  components: {
    CollapsibleStepBlock,
  },
});
