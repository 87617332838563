

















import Vue from 'vue';

import { SpinnerSize } from '@/enums/SpinnerSize';

import BulbSpinner from './BulbSpinner.vue';

export default Vue.extend({
  name: 'Spinner',
  components: {
    BulbSpinner,
  },
  props: {
    size: {
      type: Number as () => SpinnerSize,
      required: true,
    },
    disables: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    useCircleSpinner(): boolean {
      return this.size === SpinnerSize.small;
    },
  },
});
