


















import Vue from 'vue';

export default Vue.extend({
  name: 'TabDecoration',
  props: {
    isSticky: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
    },
  },
});
