













import Vue from 'vue';

import ActivateLicenses from '@/components/guide/WiZApp/StepByStep/ActivateLicenses.vue';
import CreateBuilding from '@/components/guide/WiZApp/StepByStep/CreateBuilding.vue';
import CreateOrganization from '@/components/guide/WiZApp/StepByStep/CreateOrganization.vue';
import DownloadWizApp from '@/components/guide/WiZApp/StepByStep/DownloadWizApp.vue';
import InstallAccessories from '@/components/guide/WiZApp/StepByStep/InstallAccessories.vue';
import InstallAccessoriesWithDashboard from '@/components/guide/WiZApp/StepByStep/InstallAccessoriesWithDashboard.vue';
import InstallDevicesWithApp from '@/components/guide/WiZApp/StepByStep/InstallDevicesWithApp.vue';
import InviteMemberAsOwner from '@/components/guide/WiZApp/StepByStep/InviteMemberAsOwner.vue';

export default Vue.extend({
  name: 'WiZAppStepByStepGuides',
  components: {
    CreateOrganization,
    CreateBuilding,
    InviteMemberAsOwner,
    DownloadWizApp,
    InstallDevicesWithApp,
    InstallAccessories,
    InstallAccessoriesWithDashboard,
    ActivateLicenses,
  },
});
