








import Vue from 'vue';

import CollapsibleStepBlockInjectHTML from '@/components/blocks/CollapsibleStepBlockInjectHTML.vue';

export default Vue.extend({
  name: 'InstallAccessories',
  components: {
    CollapsibleStepBlockInjectHTML,
  },
  computed: {
    i18nOpt(): Record<string, unknown> {
      return {
        AddAcc0: require('@/assets/guide/InstallAccessories/AddAcc0.png'),
        AddAcc1: require('@/assets/guide/InstallAccessories/AddAcc1.png'),
        AddAcc2: require('@/assets/guide/InstallAccessories/AddAcc2.png'),
        AddAcc3: require('@/assets/guide/InstallAccessories/AddAcc3.png'),
        AddAcc4: require('@/assets/guide/InstallAccessories/AddAcc4.png'),
        AddAcc5: require('@/assets/guide/InstallAccessories/AddAcc5.png'),
        AddAcc6: require('@/assets/guide/InstallAccessories/AddAcc6.png'),
        AddAcc7: require('@/assets/guide/InstallAccessories/AddAcc7.png'),
        AddAcc8: require('@/assets/guide/InstallAccessories/AddAcc8.png'),
        AddAcc9: require('@/assets/guide/InstallAccessories/AddAcc9.png'),
        AddAcc10: require('@/assets/guide/InstallAccessories/AddAcc10.png'),
        AddAcc11: require('@/assets/guide/InstallAccessories/AddAcc11.png'),
      };
    },
    contents(): {keys: string[]; opt?: Record<string, unknown>} {
      return {
        keys: ['HowToInstall.StepByStepGuide.InstallAccessories.Description'],
        opt: this.i18nOpt,
      };
    },
  },
});
