

































import Vue from 'vue';

export default Vue.extend({
  name: 'CollapsibleStepBlockInjectHTML',
  props: {
    title: {
      type: String,
      required: true,
    },
    contents: {
      type: Object as () => { keys: string[]; opt?: Record<string, unknown> },
      required: true,
    },
  },
  data(): { isCollapsed: boolean } {
    return {
      isCollapsed: true,
    };
  },
});
