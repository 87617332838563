








import Vue from 'vue';

import CollapsibleStepBlockInjectHTML from '@/components/blocks/CollapsibleStepBlockInjectHTML.vue';

export default Vue.extend({
  name: 'DownloadSetupApp',
  components: {
    CollapsibleStepBlockInjectHTML,
  },
  computed: {
    i18nOpt(): Record<string, unknown> {
      return {
        DownloadInstallSetupApp1: require('@/assets/setupAppGuide/DownloadApp/DownloadInstallSetupApp1.png'),
        DownloadInstallSetupApp2: require('@/assets/setupAppGuide/DownloadApp/DownloadInstallSetupApp2.png'),
        DownloadInstallSetupApp3: require('@/assets/setupAppGuide/DownloadApp/DownloadInstallSetupApp3.png'),
        DownloadInstallSetupApp4: require('@/assets/setupAppGuide/DownloadApp/DownloadInstallSetupApp4.png'),
        DownloadInstallSetupApp5: require('@/assets/setupAppGuide/DownloadApp/DownloadInstallSetupApp5.png'),
        DownloadInstallSetupApp6: require('@/assets/setupAppGuide/DownloadApp/DownloadInstallSetupApp6.png'),
        DownloadInstallSetupApp7: require('@/assets/setupAppGuide/DownloadApp/DownloadInstallSetupApp7.png'),
      };
    },
    contents(): { keys: string[]; opt?: Record<string, unknown> } {
      return {
        keys: ['HowToInstall.SetupAppStepByStepGuide.DownloadApp.Content'],
        opt: this.i18nOpt,
      };
    },
  },
});
