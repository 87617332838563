














import Vue from 'vue';

export default Vue.extend({
  name: 'AppleStoreButton',
  props: {
    SetupApp: {
      type: Boolean,
      default: false,
    },
    WiZApp: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iosAppLink(): string {
      if (this.SetupApp) {
        return process.env.VUE_APP_SETUP_APP_IOS_APP_LINK;
      }
      if (this.WiZApp) {
        return process.env.VUE_APP_IOS_APP_LINK;
      }
      return process.env.VUE_APP_SETUP_APP_IOS_APP_LINK;
    },
  },
});
