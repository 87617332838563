var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
  'w-full h-0.5',
  'mt-0.5 mx-auto',
  'hidden md:block',
  'transform-gpu transition-all',
  // hover
  this.selected ? '' : 'bg-gradient-to-r scale-x-0 group-hover:scale-x-100',
  // color
  this.selected ?
    this.isSticky ? 'bg-wizBlue' : 'bg-white' :
    this.isSticky
      ? 'from-transparent via-wizBlue to-wizBlue'
      : 'from-transparent via-white to-white'
  ]})}
var staticRenderFns = []

export { render, staticRenderFns }