// https://developers.google.com/youtube/iframe_api_reference

export enum YoutubePlayerState {
  UNSTARTED = -1,
  ENDED = 0,
  PLAYING = 1,
  PAUSED = 2,
  BUFFERING = 3,
  CUED = 5,
}

export function initYoutubeIframeApi(
  playerIframeId: string,
  videoId: string,
  callbacks?: {
    onYouTubeIframeAPIReadyCallback?: (api: YoutubeIframeAPI) => void;
    onPlayerReadyCallback?: (player: YoutubeIframePlayer, event: YoutubePlayerReadyEvent) => void;
    onPlayerStateChangeCallback?: (event: YoutubePlayerStateChangeEvent) => void;
    onPlaybackQualityChange?: (event: YoutubePlaybackQualityChangeEvent) => void;
    onPlaybackRateChange?: (event: YoutubePlaybackRateChangeEvent) => void;
    onError?: (event: YoutubePlayerErrorEvent) => void;
    onApiChange?: () => void;
  },
): void {
  // Need to define before use, TS complain
  // 4. The API will call this function when the video player is ready.
  function onPlayerReady(event: YoutubePlayerReadyEvent) {
    if (callbacks?.onPlayerReadyCallback) {
      callbacks.onPlayerReadyCallback(window.YTplayer, event);
    }
  }

  // Need to define before use, TS complain
  // 5. The API calls this function when the player's state changes.
  // https://developers.google.com/youtube/iframe_api_reference#Playback_controls
  function onPlayerStateChange(event: YoutubePlayerStateChangeEvent) {
    if (callbacks?.onPlayerStateChangeCallback) {
      callbacks.onPlayerStateChangeCallback(event);
    }
  }

  function onPlaybackQualityChange(event: YoutubePlaybackQualityChangeEvent) {
    if (callbacks?.onPlaybackQualityChange) {
      callbacks.onPlaybackQualityChange(event);
    }
  }

  function onPlaybackRateChange(event: YoutubePlaybackRateChangeEvent) {
    if (callbacks?.onPlaybackRateChange) {
      callbacks.onPlaybackRateChange(event);
    }
  }

  function onError(event: YoutubePlayerErrorEvent) {
    if (callbacks?.onError) {
      callbacks.onError(event);
    }
  }

  function onApiChange() {
    if (callbacks?.onApiChange) {
      callbacks.onApiChange();
    }
  }

  // 3. This function creates an <iframe> (and YouTube player) after the API code downloads.
  function onYouTubeIframeAPIReady() {
    // YT = window.YT;
    window.YTplayer = new window.YT.Player(playerIframeId, {
      // Can define max width/height & aspect ratio in the div that is replaced by this script.
      // height: '410',
      // width: '640',
      videoId,
      playerVars: {
        // https://developers.google.com/youtube/player_parameters#Parameters
        // autoplay: 1,
        modestbranding: 1, // hide big youtube logo, only see small one when pause
        // Valid parameter values are red and white
        // Setting the color parameter to white will disable the modestbranding option.
        // color: 'white',
        controls: 0, // This parameter indicates whether the video player controls are displayed
        rel: 0, // 0 = from the same channel as the video that was just played
        hl: 'en',
      },
      events: {
        onReady: onPlayerReady,
        onStateChange: onPlayerStateChange,
        onPlaybackQualityChange,
        onPlaybackRateChange,
        onError,
        onApiChange,
      },
    });
    if (callbacks?.onYouTubeIframeAPIReadyCallback) {
      callbacks.onYouTubeIframeAPIReadyCallback(window.YT);
    }
  }

  // 0. Add this to your code: `<div id='player'></div>`
  // 1. The <iframe> (and video player) will replace this <div> tag.
  const scriptTag = document.getElementById(playerIframeId);
  if (!scriptTag) {
    throw new Error('Target element not found');
  }
  if (!scriptTag.parentNode) {
    throw new Error('Target element parent node not found');
  }

  if (!window.YT) {
    // Download script if N/A
    // 2. This code loads the IFrame Player API code asynchronously.
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    scriptTag.parentNode.insertBefore(tag, scriptTag);
    // Once the script is ready, it will trigger `onYouTubeIframeAPIReady`.
  } else {
    // But if the script was already downloaded before, we run `onYouTubeIframeAPIReady`
    // manually to create a new player. (Needed when user travel back and forth between pages)
    onYouTubeIframeAPIReady();
  }

  // For SPA, expose event handler to global

  window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;

  window.onPlayerReady = onPlayerReady;

  window.onPlayerStateChange = onPlayerStateChange;

  window.onPlaybackQualityChange = onPlaybackQualityChange;

  window.onPlaybackRateChange = onPlaybackRateChange;

  window.onError = onError;

  window.onApiChange = onApiChange;
}

export type YoutubeVideoChapter = {
  label: string;
  timestamp: number; // seconds
};

export type YoutubeVideoInfo = {
  src: string;
  videoId: string;
  chapters: YoutubeVideoChapter[];
};
