













































import Vue from 'vue';

import AnchorTag from '@/components/buttons/AnchorTag.vue';
import { VideoForPlaylist } from '@/interfaces/VideoForPlaylist';

export default Vue.extend({
  name: 'VideosBlock',
  components: {
    AnchorTag,
  },
  props: {
    header: {
      type: String,
      required: true,
    },
    videos: {
      type: Array as () => VideoForPlaylist[],
      required: true,
    },
  },
  data(): { selectedVideoCode: string | undefined; } {
    return {
      selectedVideoCode: undefined,
    };
  },
  mounted() {
    if (this.videos.length > 0) {
      const firstVideo = this.videos[0];
      this.selectedVideoCode = firstVideo.code;
    }
  },
  computed: {
    selectedVideo(): VideoForPlaylist | undefined {
      return this.videos.find(
        (value) => value.code === this.selectedVideoCode,
      );
    },
    selectedVideoUrl(): string | undefined {
      return this.selectedVideo?.url;
    },
  },
  methods: {
    isVideoSelected(video: VideoForPlaylist): boolean {
      return video.code === this.selectedVideoCode;
    },
    selectVideo(video: VideoForPlaylist) {
      if (this.isVideoSelected(video)) {
        return;
      }
      this.selectedVideoCode = video.code;
    },
  },
});
