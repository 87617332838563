























import Vue, { PropType } from 'vue';

import { AppLocaleData } from '@/enums/AppLocale';

interface IState {
  hover: boolean;
}

export default Vue.extend({
  name: 'MenuItem',
  props: {
    locale: {
      type: Object as PropType<AppLocaleData>,
      required: true,
    },
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  data(): IState {
    return {
      hover: false,
    };
  },
  computed: {
    isHovering(): boolean {
      return this.hover;
    },
  },
  methods: {
    onLocaleSelect() {
      this.$emit('select', this.locale);
    },
  },
});
