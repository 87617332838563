








import Vue from 'vue';

import CollapsibleStepBlockInjectHTML from '@/components/blocks/CollapsibleStepBlockInjectHTML.vue';

export default Vue.extend({
  name: 'InstallAccessoriesWithDashboard',
  components: {
    CollapsibleStepBlockInjectHTML,
  },
  computed: {
    i18nOpt(): Record<string, unknown> {
      return {
        AddAccDB1: require('@/assets/guide/InstallAccessoriesWithPro/AddAccDB1.png'),
        AddAccDB2: require('@/assets/guide/InstallAccessoriesWithPro/AddAccDB2.png'),
        AddAccDB3: require('@/assets/guide/InstallAccessoriesWithPro/AddAccDB3.png'),
        AddAccDB4: require('@/assets/guide/InstallAccessoriesWithPro/AddAccDB4.png'),
        AddAccDB5: require('@/assets/guide/InstallAccessoriesWithPro/AddAccDB5.png'),
        AddAccDB6: require('@/assets/guide/InstallAccessoriesWithPro/AddAccDB6.png'),
        AddAccDB7: require('@/assets/guide/InstallAccessoriesWithPro/AddAccDB7.png'),
      };
    },
    contents(): {keys: string[]; opt?: Record<string, unknown>} {
      return {
        keys: ['HowToInstall.StepByStepGuide.InstallAccessoriesWithPro.Description'],
        opt: this.i18nOpt,
      };
    },
  },
});
