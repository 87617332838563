






import Vue from 'vue';

import {AppLocale} from '@/enums/AppLocale';

export default Vue.extend({
  name: 'GoogleStoreButton',
  props: {
    SetupApp: {
      type: Boolean,
      default: false,
    },
    WiZApp: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentEnv(): 'EU' | 'CN' {
      const currentEnv = `${process.env.VUE_APP_ENV}`.split('-')[1];
      if (currentEnv === 'EU' || currentEnv === 'CN') {
        return currentEnv;
      }
      return 'EU';
    },
    currenSelectedLocale(): AppLocale {
      return this.$store.state.locale || AppLocale.EN;
    },
    altText(): string {
      return this.currentEnv === 'EU' ? 'Download in Google Play' : 'Download APK';
    },
    imageSrc(): string {
      if (this.currentEnv === 'EU') {
        return require('@/assets/icons/downloadButtons/appStores/playStore.svg');
      }

      let filename = this.WiZApp ? 'wizApp' : 'proSetup';
      return require(`@/assets/icons/downloadButtons/apk/${this.currenSelectedLocale}/${filename}.svg`);
    },
    downloadAppLink(): string {
      return this.currentEnv === 'EU' ? this.googlePlayAppLink : this.apkLink;
    },
    googlePlayAppLink(): string {
      if (this.SetupApp) {
        return process.env.VUE_APP_SETUP_APP_GOOGLE_PLAY_APP_LINK;
      }
      if (this.WiZApp) {
        return process.env.VUE_APP_GOOGLE_PLAY_APP_LINK;
      }
      return process.env.VUE_APP_SETUP_APP_GOOGLE_PLAY_APP_LINK;
    },
    apkLink(): string {
      // https://www.collaboration.dtf.lighting.com/display/WiZC/WiZ+App+Links
      return this.SetupApp ? process.env.VUE_APP_SETUP_APP_APK_LINK : process.env.VUE_APP_APK_LINK;
    },
  },
});
