







import axios from 'axios';
import Vue, {PropType} from 'vue';

import BigSpinner from './BigSpinner.vue';
import FailedToLoadRemoteContent from './FailedToLoadRemoteContent.vue';
import {getUrlWithLocale} from './helper/LegalDocHelper';

export default Vue.extend({
  name: 'TemplatePage',
  components: {
    FailedToLoadRemoteContent,
    BigSpinner,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    parser: {
      type: Function as PropType<((requestResult: string) => string) | undefined>,
      required: false,
    },
  },
  data(): {
    loading: boolean;
    urlContent: string | undefined;
    failedToLoad: boolean;
  } {
    return {
      loading: false,
      urlContent: undefined,
      failedToLoad: false,
    };
  },
  computed: {
    locale(): string {
      return this.$store.state.locale;
    },
  },
  methods: {
    async getUrlContent() {
      this.urlContent = undefined;
      this.loading = true;
      try {
        let tosUrl = getUrlWithLocale(this.url, this.locale) || this.url;
        if (window.location.hostname !== 'localhost') {
          tosUrl = process.env.VUE_APP_URL + tosUrl;
        }
        const fetchData = await axios.get<string>(tosUrl);
        this.urlContent = this.parser !== undefined ? this.parser(fetchData.data) : fetchData.data;
      } catch (e) {
        this.failedToLoad = true;
        this.$emit('loading-failed');
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    url() {
      this.getUrlContent();
    },
    locale() {
      this.getUrlContent();
    },
  },
  mounted() {
    this.getUrlContent();
  },
});
