import Vue from 'vue';

Vue.directive('scroll-animate', {
  bind: async (el, binding) => {
    await Vue.nextTick();
    const className = binding.value;
    el.classList.add('opacity-0');

    const scrollHandler = () => {
      const { top } = el.getBoundingClientRect();
      if (top < window.innerHeight) {
        el.classList.add(className);
        el.classList.remove('opacity-0');
        window.removeEventListener('scroll', scrollHandler);
      }
    };

    scrollHandler();
    window.addEventListener('scroll', scrollHandler);
  },
});
