import VueI18n from 'vue-i18n';
import {Store} from 'vuex';

import {AppLocale, getCachedLocalePreference} from '@/enums/AppLocale';

export interface AppStore {
  locale: AppLocale;
}

export function initiateStore(i18n: VueI18n): Store<AppStore> {
  return new Store<AppStore>({
    state: {
      locale: getCachedLocalePreference(),
    },
    actions: {
      changeLocale(context, locale: AppLocale) {
        context.commit('setLocale', locale);
        i18n.locale = locale;
      },
    },
    mutations: {
      setLocale(state, locale: AppLocale) {
        state.locale = locale;
        // To persist after refresh
        localStorage.setItem('locale', locale);
      },
    },
  });
}
