






































































































































import Vue from 'vue';

import InstallationStepIcon from '@/components/pictograms/InstallationStepIcon.vue';

export default Vue.extend({
  name: 'InstallationFlow',
  components: {
    InstallationStepIcon,
  },
  props: {
    enableScrollEffect: {
      type: Boolean,
      default: false,
    },
  },
  data(): { isContainerScrolling: boolean } {
    return {
      isContainerScrolling: false,
    };
  },
  created() {
    if (this.enableScrollEffect) {
      window.addEventListener('scroll', this.handleWindowScroll);
    }
  },
  destroyed() {
    if (this.enableScrollEffect) {
      window.removeEventListener('scroll', this.handleWindowScroll);
    }
  },
  methods: {
    handleWindowScroll() {
      const el = this.$refs.container as HTMLElement;
      const top = document.documentElement.scrollTop;
      const width = window.innerWidth;
      const pos = width > 768 ? top * -0.9 : 0;
      el.style.transform = `translate3D(0, ${pos}px, 0)`;
    },
    handleContainerScroll() {
      const el = this.$refs['scroll-container'] as HTMLElement;
      const top = el.scrollTop;
      this.isContainerScrolling = top > 0;
    },
  },
});
