











import Vue from 'vue';
import VueRouter from 'vue-router';

export default Vue.extend({
  name: 'AnchorTag',
  props: {
    href: {
      type: String,
      required: true,
    },
    extraClasses: {
      type: Array as () => string[],
      default: () => ([]),
    },
  },
  methods: {
    navigateToHref() {
      const { isNavigationFailure, NavigationFailureType } = VueRouter;
      this.$router.push(this.href).catch(
        (error) => {
          if (!isNavigationFailure(error, NavigationFailureType.duplicated)) {
            throw Error(error);
          }
        },
      );
    },
  },
});
