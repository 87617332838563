











import Vue from 'vue';

import VersionInfo from '@/components/VersionInfo.vue';

export default Vue.extend({
  name: 'Copyright',
  components: {
    VersionInfo,
  },
  computed: {
    text() {
      return this.$t('Copyright', {
        year: new Date().getFullYear().toString(),
      });
    },
  },
});
