export enum AppLocale {
  EN = 'en',
  CN = 'cn',
  // FR = 'fr',
  // RU = 'ru',
}

export type AppLocaleData = {
  locale: AppLocale;
  label: string;
};

export const AppLocaleDatas: AppLocaleData[] = [
  {locale: AppLocale.EN, label: 'English'},
  {locale: AppLocale.CN, label: '中文'},
  // { locale: AppLocale.FR, label: 'Français' },
  // { locale: AppLocale.RU, label: 'Pусский язык' },
];

export function isLocaleSupported(locale = ''): boolean {
  return locale.toLocaleUpperCase() in AppLocale;
}

export function getLocaleData(locale = ''): AppLocaleData {
  const target = isLocaleSupported(locale) ? locale : AppLocale.EN;
  return AppLocaleDatas.find((d) => d.locale === target) || AppLocaleDatas[0];
}

export function getCachedLocalePreference(): AppLocale {
  const cached = localStorage.getItem('locale') || '';
  const data = getLocaleData(cached);
  return data.locale;
}
