




















import Vue from 'vue';

export default Vue.extend({
  name: 'CollapsibleStepBlock',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data(): { isCollapsed: boolean; } {
    return {
      isCollapsed: true,
    };
  },
});
