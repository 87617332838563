








import Vue from 'vue';

import CollapsibleStepBlockInjectHTML from '@/components/blocks/CollapsibleStepBlockInjectHTML.vue';

export default Vue.extend({
  name: 'ClaimOwnership',
  components: {
    CollapsibleStepBlockInjectHTML,
  },
  computed: {
    i18nOpt(): Record<string, unknown> {
      return {
        ClaimOwnership1: require('@/assets/setupAppGuide/ClaimOwnership/ClaimOwnership1.png'),
        ClaimOwnership2: require('@/assets/setupAppGuide/ClaimOwnership/ClaimOwnership2.png'),
        ClaimOwnership3: require('@/assets/setupAppGuide/ClaimOwnership/ClaimOwnership3.png'),
        ClaimOwnership4: require('@/assets/setupAppGuide/ClaimOwnership/ClaimOwnership4.png'),
        ClaimOwnership5: require('@/assets/setupAppGuide/ClaimOwnership/ClaimOwnership5.png'),
        ClaimOwnership6: require('@/assets/setupAppGuide/ClaimOwnership/ClaimOwnership6.png'),
      };
    },
    contents(): { keys: string[]; opt?: Record<string, unknown> } {
      return {
        keys: ['HowToInstall.SetupAppStepByStepGuide.ClaimOwnership.Content'],
        opt: this.i18nOpt,
      };
    },
  },
});
