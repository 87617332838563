













import Vue from 'vue';

import ActivateLicenses from '@/components/guide/SetupApp/StepByStep/ActivateLicenses.vue';
import AddProduct from '@/components/guide/SetupApp/StepByStep/AddProduct.vue';
import ClaimOwnership from '@/components/guide/SetupApp/StepByStep/ClaimOwnership.vue';
import CreateSite from '@/components/guide/SetupApp/StepByStep/CreateSite.vue';
import DecideOnConnectivity from '@/components/guide/SetupApp/StepByStep/DecideOnConnectivity.vue';
import DownloadSetupApp from '@/components/guide/SetupApp/StepByStep/DownloadSetupApp.vue';
import Handover from '@/components/guide/SetupApp/StepByStep/Handover.vue';
import SyncProducts from '@/components/guide/SetupApp/StepByStep/SyncProducts.vue';

export default Vue.extend({
  name: 'SetupAppStepByStepGuides',
  components: {
    DownloadSetupApp,
    CreateSite,
    AddProduct,
    DecideOnConnectivity,
    SyncProducts,
    Handover,
    ClaimOwnership,
    ActivateLicenses,
  },
});
