








import Vue from 'vue';

import CollapsibleStepBlockInjectHTML from '@/components/blocks/CollapsibleStepBlockInjectHTML.vue';

export default Vue.extend({
  name: 'ActivateLicenses',
  components: {
    CollapsibleStepBlockInjectHTML,
  },
  computed: {
    i18nOpt(): Record<string, unknown> {
      return {
        ActivateLicense1: require('@/assets/guide/ActivateLicenses/ActivateLicense1.jpg'),
        ActivateLicense2: require('@/assets/guide/ActivateLicenses/ActivateLicense2.jpg'),
        ActivateLicense3: require('@/assets/guide/ActivateLicenses/ActivateLicense3.jpg'),
      };
    },
    contents(): { keys: string[]; opt?: Record<string, unknown> } {
      return {
        keys: ['HowToInstall.StepByStepGuide.ActivateLicenses.Description'],
        opt: this.i18nOpt,
      };
    },
  },
});
