























































































































































































































































































import Vue from 'vue';

import AppleStoreButton from '@/components/AppStoreButtons/AppleStore.vue';
import GoogleStoreButton from '@/components/AppStoreButtons/GoogleStore.vue';
import InstallationFlow from '@/components/blocks/InstallationFlow.vue';
import VideosBlock from '@/components/blocks/VideosBlock.vue';
import VideosBlockWithChapters from '@/components/blocks/VideosBlockWithChapters.vue';
import AnchorTag from '@/components/buttons/AnchorTag.vue';
import OutlinedButton from '@/components/buttons/OutlinedButton.vue';
import SetupAppStepByStepGuides from '@/components/guide/SetupApp/StepByStep/index.vue';
import WiZAppStepByStepGuides from '@/components/guide/WiZApp/StepByStep/index.vue';
import ServiceIconSmall from '@/components/pictograms/ServiceIconSmall.vue';
import BulletListItem from '@/components/templates/BulletListItem.vue';
import CutBlock from '@/components/templates/CutBlock.vue';
import CutBlockWithStripes from '@/components/templates/CutBlockWithStripe.vue';
import ServiceCardSmall from '@/components/templates/ServiceCardSmall.vue';
import ServiceCardsWrapper from '@/components/templates/ServiceCardsWrapper.vue';
import ServiceCardTitle from '@/components/templates/ServiceCardTitle.vue';
import HowToConfigure from '@/components/wiFiRequirements/HowToConfigure.vue';
import PlanningWiFiNetwork from '@/components/wiFiRequirements/PlanningWiFiNetwork.vue';
import TypeOfWiFi from '@/components/wiFiRequirements/TypeOfWiFi.vue';
import UseHotspot from '@/components/wiFiRequirements/UseHotspot.vue';
import {VideoForPlaylist} from '@/interfaces/VideoForPlaylist';
import {scrollToElementWithOffset} from '@/utilities/ScrollUtilities';
import {navigateToURL} from '@/utilities/URLNavigation';
import {YoutubeVideoInfo} from '@/utilities/YoutubeIframeApi';

interface IState {
  currentView: 'useSetupAppView' | 'notUseSetupAppView';
}

export default Vue.extend({
  name: 'HowToInstall',
  components: {
    CutBlockWithStripes,
    CutBlock,
    OutlinedButton,
    InstallationFlow,
    ServiceCardSmall,
    ServiceIconSmall,
    ServiceCardTitle,
    ServiceCardsWrapper,
    VideosBlock,
    VideosBlockWithChapters,
    AnchorTag,
    TypeOfWiFi,
    HowToConfigure,
    PlanningWiFiNetwork,
    UseHotspot,
    BulletListItem,
    AppleStoreButton,
    GoogleStoreButton,
    SetupAppStepByStepGuides,
    WiZAppStepByStepGuides,
  },
  data(): IState {
    return {
      currentView: 'useSetupAppView',
    };
  },
  computed: {
    videosAvailable(): boolean {
      return this.isSetupAppView
        ? !!this.installationProcessVideosWithSetupApp?.videoId
        : this.installationProcessVideosWithWiZApp.length > 0;
    },
    isSetupAppView(): boolean {
      return this.currentView === 'useSetupAppView';
    },
    installationProcessVideosWithWiZApp(): VideoForPlaylist[] {
      switch (process.env.VUE_APP_VIDEOS_PROVIDER) {
        case 'youtube':
          return [
            {
              code: 'getting-started',
              url: 'https://www.youtube.com/embed/HEerckcLb20',
              name: this.$t('HowToInstall.GettingStarted').toString(),
            },
            {
              code: 'member-invitation',
              url: 'https://www.youtube.com/embed/RJMa0GRDNZk',
              name: this.$t('HowToInstall.MemberInvitation').toString(),
            },
            {
              code: 'product-commissioning',
              url: 'https://www.youtube.com/embed/xKe0bPqY6Ic',
              name: this.$t('HowToInstall.ProductCommissioning').toString(),
            },
          ];
        default:
          return [];
      }
    },
    installationProcessVideosWithSetupApp(): YoutubeVideoInfo | undefined {
      switch (process.env.VUE_APP_VIDEOS_PROVIDER) {
        case 'youtube':
          return {
            src: 'https://www.youtube.com/watch?v=i43YJXUh9cU',
            videoId: 'i43YJXUh9cU',
            chapters: [
              {label: `${this.$i18n.t('HowToInstall.VideosBlock.SetupAppVideo.chapter1')}`, timestamp: 0},
              {label: `${this.$i18n.t('HowToInstall.VideosBlock.SetupAppVideo.chapter2')}`, timestamp: 43},
              {label: `${this.$i18n.t('HowToInstall.VideosBlock.SetupAppVideo.chapter3')}`, timestamp: 67},
              {label: `${this.$i18n.t('HowToInstall.VideosBlock.SetupAppVideo.chapter4')}`, timestamp: 105},
              {label: `${this.$i18n.t('HowToInstall.VideosBlock.SetupAppVideo.chapter5')}`, timestamp: 152},
              {label: `${this.$i18n.t('HowToInstall.VideosBlock.SetupAppVideo.chapter6')}`, timestamp: 175},
              {label: `${this.$i18n.t('HowToInstall.VideosBlock.SetupAppVideo.chapter7')}`, timestamp: 221},
            ],
          };
        default:
          return undefined;
      }
    },
    proDashboardLink(): string {
      return process.env.VUE_APP_DASHBOARD_HOST;
    },
  },
  methods: {
    setupAppLearnMoreClick() {
      if (this.videosAvailable) {
        this.scrollToVideos();
      } else {
        this.scrollToStepByStepGuide();
      }
    },
    navigateTo(url: string) {
      navigateToURL(url);
    },
    scrollToPreparation() {
      const {preparationBlock} = this.$refs;
      scrollToElementWithOffset((preparationBlock as Vue).$el, -90);
    },
    scrollToWiFiRequirement() {
      const {wiFiHeaderBlock} = this.$refs;
      scrollToElementWithOffset(wiFiHeaderBlock as Element, -90);
    },
    scrollToStepByStepGuide() {
      const {stepByStepGuideBlock} = this.$refs;
      scrollToElementWithOffset(stepByStepGuideBlock as Element, -90);
    },
    scrollToVideos() {
      const {videosBlock} = this.$refs;
      scrollToElementWithOffset((videosBlock as Vue).$el, -90);
    },
    onClickUseSetupApp() {
      if (this.currentView === 'useSetupAppView') {
        this.scrollToPreparation();
      } else {
        this.currentView = 'useSetupAppView';
        this.$nextTick(() => {
          this.scrollToPreparation();
        });
      }
    },
    onClickNotUseSetupApp() {
      if (this.currentView === 'notUseSetupAppView') {
        this.scrollToPreparation();
      } else {
        this.currentView = 'notUseSetupAppView';
        this.$nextTick(() => {
          this.scrollToPreparation();
        });
      }
    },
  },
});
