import VueI18n from 'vue-i18n';

import {AppLocale} from '@/enums/AppLocale';
import cn from '@/localization//locales/cn.json';
import en from '@/localization//locales/en.json';

const messages = {
  en,
  cn,
};

export function initiateLocalization(locale: AppLocale): VueI18n {
  return new VueI18n({
    locale,
    messages,
  });
}
