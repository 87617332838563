














































import Vue, { PropType } from 'vue';

import AnchorTag from '@/components/buttons/AnchorTag.vue';
import { initYoutubeIframeApi, YoutubePlayerState, YoutubeVideoChapter } from '@/utilities/YoutubeIframeApi';

// Only adding these 2 here because the loader couldnt find the definition in d.ts file
// eslint-disable-next-line
type YoutubeIframeAPI = any;
// eslint-disable-next-line
type YoutubeIframePlayer = any;

export default Vue.extend({
  name: 'VideosBlockWithChapters',
  components: {
    AnchorTag,
  },
  props: {
    header: {
      type: String,
      required: true,
    },
    youtubeVideoId: {
      type: String,
      required: true,
    },
    videoChapterMetas: {
      type: Array as PropType<YoutubeVideoChapter[]>,
      default: () => [],
    },
    isHidden: {
      type: Boolean,
    },
  },
  data(): {
    youtubeApi: YoutubeIframeAPI | undefined;
    player: YoutubeIframePlayer | undefined;
    } {
    return {
      youtubeApi: undefined,
      player: undefined,
    };
  },
  mounted() {
    initYoutubeIframeApi('player', this.youtubeVideoId, {
      onYouTubeIframeAPIReadyCallback: (api) => { this.youtubeApi = api; },
      onPlayerReadyCallback: (player) => { this.player = player; },
    });
  },
  watch: {
    isHidden(hidden: boolean) {
      if (hidden) {
        if (this.player) {
          this.player.pauseVideo();
        }
      }
    },
  },
  methods: {
    determineButtonColor(/* chapter: YoutubeVideoChapter */) {
      // TODO: future
      // check video is playing in current chapter, decide button color
      // player.getCurrentTime():Number
      return true;
    },
    onVideoChapterSelected(chapter: YoutubeVideoChapter) {
      this.player.seekTo(chapter.timestamp);
      if (this.player.getPlayerState() !== YoutubePlayerState.PLAYING) {
        this.player.playVideo();
      }
    },
  },
});
