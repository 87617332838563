








import Vue from 'vue';

import CollapsibleStepBlockInjectHTML from '@/components/blocks/CollapsibleStepBlockInjectHTML.vue';

export default Vue.extend({
  name: 'CreateBuilding',
  components: {
    CollapsibleStepBlockInjectHTML,
  },
  computed: {
    i18nOpt(): Record<string, unknown> {
      return {
        CreateLocation1: require('@/assets/guide/CreateBuilding/CreateLocation1.png'),
        CreateLocation2: require('@/assets/guide/CreateBuilding/CreateLocation2.jpg'),
        CreateLocation3: require('@/assets/guide/CreateBuilding/CreateLocation3.jpg'),
        CreateLocation4: require('@/assets/guide/CreateBuilding/CreateLocation4.jpg'),
        CreateLocation5: require('@/assets/guide/CreateBuilding/CreateLocation5.jpg'),
        CreateLocation6: require('@/assets/guide/CreateBuilding/CreateLocation6.jpg'),
        CreateLocation7: require('@/assets/guide/CreateBuilding/CreateLocation7.jpg'),
      };
    },
    contents(): { keys: string[]; opt?: Record<string, unknown> } {
      return {
        keys: ['HowToInstall.StepByStepGuide.CreateBuilding.Description'],
        opt: this.i18nOpt,
      };
    },
  },
});
